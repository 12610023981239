<template>
    <div>

        <rxNavBar title="" androidOrIOSFlag="true"></rxNavBar>
      <van-empty image="error">
        <template v-slot:description>
          <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">

            <div>暂不开放此功能，如有需要请移步</div>
            <div>【员工更多=>员工内部推荐】</div>
          </div>
        </template>
      </van-empty>
<!--      <van-empty image="error" :description="`暂不开放此功能，\n-->
<!--      如有需要请移步【员工内部推荐】`" />-->
<!--        <div>-->
<!--            <div class="title-part">-->
<!--                <img src="../../../assets/images/head-quotation-mark.png">-->
<!--                <span>推荐简历</span>-->
<!--            </div>-->
<!--            <div class="part">-->
<!--                <div class="part-inputpart">-->
<!--                    <div class="part-inputpart-row">-->
<!--                        <div :class=" 0==name.trim().length ? 'content-none' : 'content-have'"></div>-->
<!--                        <span class="part-inputpart-row-header">姓名</span>-->
<!--                        <span class="content-divide">|</span>-->
<!--                        <input v-model="name" placeholder="请输入姓名">-->
<!--                    </div>-->
<!--                    <div class="part-inputpart-row">-->
<!--                        <div :class=" 0==sex.trim().length ? 'content-none' : 'content-have'"></div>-->
<!--                        <span class="part-inputpart-row-header">性别</span>-->
<!--                        <div>-->
<!--                            <span class="part-inputpart-man" :class="'1'==sex ? 'part-inputpart-row-redtext' : 'part-inputpart-row-graytext' " @click="mansex">男</span>-->
<!--                            <span class="content-divide">|</span>-->
<!--                            <span class="part-inputpart-woman" :class="'0'==sex ? 'part-inputpart-row-redtext' : 'part-inputpart-row-graytext' " @click="womansex">女</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div id="recommendTypePanel" class="part-inputpart-row" @click="isRecommendTypeShow = !isRecommendTypeShow">-->
<!--                        <div :class=" !recommendType.recommendTypeName ? 'content-none' : 'content-have'"></div>-->
<!--                        <span class="part-inputpart-row-header">途径</span>-->
<!--                        <span class="content-divide">|</span>-->
<!--                        <span class="part-inputpart-row-graytext" :class="!recommendType.recommendTypeName ? '' : 'part-inputpart-row-normaltext' ">{{recommendType.recommendTypeName}}</span>-->
<!--                        <img class="part-inputpart-row-right" :class=" isRecommendTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isRecommendTypeShow">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="part-inputpart-dropDownList-option" :class="item==recommendType ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in recommendTypeArr" @click="selectRecommendTypeOption(item)">{{item.recommendTypeName}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <div class="part-inputpart-row">-->
<!--                        <span :class=" 0==linkWay.trim().length ? 'content-none' : 'content-have' "></span>-->
<!--                        <span class="part-inputpart-row-header">联系方式</span>-->
<!--                        <span class="content-divide">|</span>-->
<!--                        <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" v-model="linkWay" placeholder="填写联系方式" @blur="checkPhone">-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div @click="saveData" class="part part-button" :class="0==name.trim().length || 0==linkWay.trim().length || 0==sex.trim().length || !recommendType.recommendTypeName  ? '' : 'part-button-enabled' ">确定</div>-->
<!--        </div>-->
<!--      <van-popup v-model="isRecommendTypeShow" position="bottom">-->
<!--        <van-picker-->
<!--            show-toolbar-->
<!--            :columns="recommendTypeArr"-->
<!--            @cancel="isRecommendTypeShow = false"-->
<!--            :default-index="recommendTypeIndex"-->
<!--            value-key="recommendTypeName"-->
<!--            @confirm="selectRecommendTypeOption"-->
<!--        />-->
<!--      </van-popup>-->
    </div>
</template>

<script>

import {
  Empty,
  NavBar, Picker, Popup, Toast
} from 'vant'
    import {RecommendResumeInit, RecommendResumeSave, test} from "../../../getData/getData";
    import {globaluserId,checkAndroid,
        checkIOS, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
        window.himi.closeWindow();
    }
    //调用android关闭页面方法*******end***********
    export default {

        name: "RecommendResume",

        components: {
            [NavBar.name]: NavBar,
            [Toast.name]: Toast,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
          [Empty.name]: Empty,
          rxNavBar
        },

        data() {
            return {
              recommendTypeIndex: '',
                //途径
                isRecommendTypeShow: false,
                isRecommenderSelected: false,
                name: '',
                sex: '',
                linkWay: '',
                recommendType: '',
                recommendTypeArr: []
            }
        },

        mounted: function () {
            // 初始化
            this.initData()
        },

        methods: {

            checkPhone() {
                var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if('' != this.linkWay){
                    if(!reg.test(this.linkWay)){
                        Toast('手机号格式错误')
                        this.linkWay = ''
                    }
                }
            },

            //返回上一层
            leftReturn(){
                this.$router.go(-1);
                //APP传值*******begin*********
                if(checkAndroid()){
                    backToAndroid();
                }else if(checkIOS()){
                    console.log("对ios传参");
                    window.webkit.messageHandlers.closeWindow.postMessage({});
                }
                //APP传值*******end*********

            },
            //点击下拉框区域外 下拉框隐藏
            clickRecommendTypeShow() {
                this.isRecommendTypeShow = !this.isRecommendTypeShow;
                var panel = document.getElementById('recommendTypePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isRecommendTypeShow = false
                        }
                    })
                }

            },
            //点击下拉框选项 进行数据绑定
            selectRecommendTypeOption(item,index){
              if(item){
                this.recommendType = item
                this.recommendTypeIndex = index
              }
                this.isRecommendTypeShow = false
                this.isRecommenderSelected = true

            },

            mansex(){
                this.sex = '1'
            },

            womansex(){
                this.sex = '0'
            },

            // 页面初始化
            initData: function () {
                var that = this
                let initData = {}
                initData.user_id = globaluserId
                RecommendResumeInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.recommendTypeArr = response.data.data.recommendTypeList
                    })
                })
            },

            // 保存数据
            saveData: function () {
                var that = this
                let initData = {}
                if(0==that.name.trim().length) return
                if(0==that.linkWay.trim().length) return
                if(0==that.sex.trim().length) return
                if(!that.isRecommenderSelected) return
                initData.user_id = globaluserId()
                initData.name = that.name
                initData.sex = that.sex
                initData.recommendType = that.recommendType.recommendType_id
                initData.linkWay = that.linkWay
                RecommendResumeSave(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that,'推荐简历成功')
                        that.leftReturn();
                    })
                })
            },

        }


    }
</script>

<style scoped>

    .title-part{
        position: relative;
        margin-top: 24px;
    }

    .title-part img{
        width: 68px;
    }

    .title-part span{
        position: absolute;
        z-index: 10;
        font-weight: 900;
        font-size: 27px;
        top: 39px;
        left: 33px;
    }

    .part {
        margin: 15px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-button{
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 100px;
    }

    .part-button-enabled{
        background-image:linear-gradient(to right,#ffc274, #ff5d3b);
    }

    .part-inputpart div:last-child{
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-header{
        font-weight: 900;
    }

    .part-inputpart-row input {
        border: 0;
        /*width: 100%;*/
      flex: auto;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected{
        color: #ff5d3b;
    }

    .part-inputpart-row-graytext {
        /*width: 100%;*/
        color: #d8d8d8;
      flex: auto;
    }

    .part-inputpart-row-redtext{
        color: #ff5d3b;
    }

    .part-inputpart-row-normaltext{
        color: black;
    }

    .part-inputpart-man{
        margin-left: 150px;
        margin-right: 18px;
    }

    .part-inputpart-woman{
        margin-left: 18px;
    }

    .part-inputpart-row-right{
        float: right;
        margin-right: 10px;
    }

    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }




</style>
